<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <div style="padding:0 0 40px;">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <el-date-picker value-format="yyyy-MM-dd" @change="changeDate" v-model="bindDateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false">
            </el-date-picker>
          </el-row>
        </el-row>
      </el-row>
      <div class="title">
        <h3>营销额概况</h3>
      </div>
      <el-row type="flex" align="center" class="card-box">
        <div class="d-flex-y-center card-box-item">
          <div>
            <p class="card-tit">营业总额(元)</p>
            <p class="card-data">
              ￥{{statistics.total || 0}}
            </p>
            <!-- <p class="card-ratio">日环比<span style="color:#FF2332" class="el-icon-top-right">0%</span></p> -->
          </div>
          <div id="chart-mini"></div>
        </div>
        <div class="d-flex-y-center card-box-item">
          <div class="border"></div>
          <img class="card-icon" src="@/assets/summary-icon1.png" alt="">
          <div>
            <p class="card-tit">房费(元)</p>
            <p class="card-data"> ￥{{statistics.roomFees || 0}} </p>
            <p class="card-ratio">占比{{statistics.roomFeesPercent || 0}}%</p>
          </div>
        </div>
        <div class="d-flex-y-center card-box-item">
          <div class="border"></div>
          <img class="card-icon" src="@/assets/summary-icon2.png" alt="">
          <div>
            <p class="card-tit">违约金</p>
            <p class="card-data">￥{{statistics.penalSum || 0}}</p>
            <p class="card-ratio">占比 {{statistics.penalSumPercent || 0}}%</p>
          </div>
        </div>
        <div class="d-flex-y-center card-box-item">
          <div class="border"></div>
          <img class="card-icon" src="@/assets/summary-icon3.png" alt="">
          <div>
            <p class="card-tit">客房消费</p>
            <p class="card-data"> ￥{{statistics.consume || 0}} </p>
            <p class="card-ratio">占比 {{statistics.consumePercent || 0}}%</p>
          </div>
        </div>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="9">
          <div class="title">
            <h3>营业绘制统计</h3>
          </div>
          <div id="chartTotal"></div>
        </el-col>
        <el-col :span="15" style="position:relative;">
          <div class="title">
            <h3>每日营业统计</h3>
          </div>
          <el-radio-group v-model="lineChartType" style="position:absolute;top:85px;left:10px;z-index:99999" @change="getLineChart">
            <el-radio-button label="1">营业总额</el-radio-button>
            <el-radio-button label="2">违约金</el-radio-button>
            <el-radio-button label="3">客房消费</el-radio-button>
            <el-radio-button label="4">房费</el-radio-button>
          </el-radio-group>
          <div id="chartToday">

          </div>
        </el-col>
      </el-row>
      <div class="title d-flex">
        <div style="display:flex;align-items:flex-end;">
          <h3 class="m-right-10">营销额概况</h3>
          <span class="title-date">{{bindDateRange[0]}} - {{bindDateRange[1]}}</span>
        </div>
        <el-button class="m-left-auto" icon="el-icon-back" @click="changeData('before')" :disabled="page===0">前{{limit}}天</el-button>
        <el-button @click="changeData('after')" :disabled="page >= dataLength/limit - 1">后{{limit}}天<i class="el-icon-right el-icon--right"></i></el-button>
        <el-button type="primary" @click="exportSummaryExcel">导出报表</el-button>
      </div>
      <el-row type="flex" justify="betweent" class="tab-header">
        <el-col class="tab-body-item">项目</el-col>
        <el-col class="tab-body-item">明细</el-col>
        <el-col class="tab-body-item">合计</el-col>
        <!-- <el-col v-for="(item,idx) in spliceData" :key="idx">{{item}}</el-col> -->
        <el-col class="tab-body-item" v-for="(item,idx) in originData.sliceDates" :key="idx">{{item}}</el-col>
      </el-row>
      <el-row v-for="(list,listIdx) in originData.list" :key="listIdx">
        <el-row type="flex" justify="betweent" class="tab-body" v-for="(item,idx) in list.list" :key="idx">
          <el-col class="tab-body-item">{{ idx === 0 ? list.name : ""}}</el-col>
          <el-col class="tab-body-item">{{item.name}}</el-col>
          <el-col class="tab-body-item">￥{{item.totalAmount}}</el-col>
          <!-- <el-col class="tab-body-item" v-for="(item,key) in spliceData" :key="key">{{item}}</el-col> -->
          <el-col class="tab-body-item" v-for="(money,moneyIdx) in originData.sliceDates" :key="moneyIdx">{{item.sliceDailyAmounts[moneyIdx] || 0}}</el-col>
        </el-row>
      </el-row>

      <!-- <el-row class="tab-body">客房消费</el-row>
      <el-row class="tab-body">违约金</el-row> -->
      <el-row class="tab-body" type="flex" justify="betweent">
        <el-col class="tab-body-item">合计</el-col>
        <el-col class="tab-body-item" style="border-left:1px solid #FFF;"></el-col>
        <el-col class="tab-body-item">￥{{originData.allTotalAmount}}</el-col>
        <el-col class="tab-body-item" v-for="(item,idx) in originData.sliceDailyAllAmounts" :key="idx">{{item}}</el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { dateFactory, exportFile } from "@/common/js/common";
import {
  getStatistics,
  getLineChart,
  getSumFormData,
  exportSummaryExcel,
} from "@/api/pms/statistics/businessSummary";
import { mapState } from "vuex";
export default {
  name: "arrivingTodaySummary",
  data() {
    return {
      statistics: {},
      lineChartType: "1",
      crumbs: new Map([["PMS"], ["统计中心"], ["营业汇总"]]),
      bindDateRange: [
        dateFactory.getDistanceToday(-7, false),
        dateFactory.getDistanceToday(0, false),
      ],
      dateRange: [],
      name: "first",
      dataLength: 1, // 数据长度
      page: 0,
      limit: 10,
      originData: [
        "2022/02/17",
        "2022/02/18",
        "2022/02/19",
        "2022/02/20",
        "2022/02/21",
        "2022/02/22",
        "2022/02/23",
        "2022/02/24",
      ],
      spliceData: [],
    };
  },
  mounted() {
    this.getStatistics();
    this.getLineChart();
    this.getSumFormData();
  },
  methods: {
    exportSummaryExcel() {
      if (this.originData.list.length === 0) {
        return this.$message({showClose: true,message:"没有数据可以导出",type: 'error'});
      }
      // 报表导出
      let data = {
        beginDateStr: this.bindDateRange[0],
        endDateStr: this.bindDateRange[1],
        hotelId: this.hotelInfo.id,
      };
      exportSummaryExcel(data).then((res) => {
        exportFile(res, "营业汇总");
      });
    },
    getSumFormData() {
      let data = {
        beginDateStr: this.bindDateRange[0],
        endDateStr: this.bindDateRange[1],
        hotelId: this.hotelInfo.id,
      };
      getSumFormData(data).then((res) => {
        this.dataLength = res.records.dates.length;
        res.records.sliceDates = res.records.dates.slice(0, this.limit);
        res.records.sliceDailyAllAmounts = res.records.dailyAllAmounts.slice(
          0,
          this.limit
        );
        res.records.list.forEach((list) => {
          list.list.map((listItem) => {
            listItem.sliceDailyAmounts = listItem.dailyAmounts.slice(
              0,
              this.limit
            );
          });
        });
        this.originData = res.records;
      });
    },
    getStatistics() {
      // 获取营业额概况
      let data = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.bindDateRange[0],
        endDateStr: this.bindDateRange[1],
      };
      getStatistics(data).then((res) => {
        this.statistics = res.records;
        this.initToatlEchart();
      });
    },

    getLineChart() {
      let data = {
        beginDateStr: this.bindDateRange[0],
        endDateStr: this.bindDateRange[1],
        hotelId: this.hotelInfo.id,
        type: this.lineChartType,
      };
      getLineChart(data).then((res) => {
        if (this.lineChartType == 1) {
          this.initMiniEchart(res.records);
        }
        this.initToday(res.records);
      });
    },

    changeData(type) {
      // 控制前五天，以及后五天
      if (type === "before") {
        if (this.page <= 0) {
          return;
        }
        this.page--;
      }
      if (type === "after") {
        if (this.page * this.limit >= this.dataLength) {
          return;
        }
        this.page++;
      }
      let originData = this.originData;
      originData.sliceDates = originData.dates.slice(
        this.page * this.limit,
        (this.page + 1) * this.limit
      );
      originData.sliceDailyAllAmounts = originData.dailyAllAmounts.slice(
        this.page * this.limit,
        (this.page + 1) * this.limit
      );
      originData.list.forEach((list) => {
        list.list.map((listItem) => {
          listItem.sliceDailyAmounts = listItem.dailyAmounts.slice(
            this.page * this.limit,
            (this.page + 1) * this.limit
          );
        });
      });
    },
    changeDate(date) {
      this.bindDateRange = date;
      this.dataLength = dateFactory.getDistanceDays(date[0], date[1]) + 1;
      this.getStatistics();
      this.getLineChart();
      this.getSumFormData();
    },
    initMiniEchart(data) {
      let xAxisData = [],
        seriesData = [];
      data.forEach((item) => {
        xAxisData.push(item.date);
        seriesData.push(item.value);
      });
      var myChart = echarts.init(document.getElementById("chart-mini"), null, {
        width: 140,
        height: 80,
      });
      let miniOption = {
        xAxis: {
          show: false,
          data: xAxisData,
        },
        yAxis: { show: false },
        series: [
          {
            data: seriesData,
            type: "line",
            // smooth: true,
            symbol: "none",
          },
        ],
      };
      myChart.setOption(miniOption);
    },

    initToatlEchart() {
      var myChart = echarts.init(document.getElementById("chartTotal"));
      let totalOption = {
        tooltip: {
          trigger: "item",
        },
        icon: "roundRect",
        legend: {
          // orient: 'vertical',
          // bottom: 100,
          // top: 'center',
          // ...

          top: "bottom",
          left: "left",
        },
        series: [
          {
            label: {
              formatter: `总营业额(元): \n\n ￥${this.statistics.total || 0}`,
              show: true,
              position: "center",
            },
            type: "pie",
            data: [
              {
                value: this.statistics.roomFees || 0,
                name: "房费",
              },
              {
                value: this.statistics.penalSum || 0,
                name: "违约金",
              },
              {
                value: this.statistics.consume || 0,
                name: "客房消费",
              },
            ],
            radius: ["40%", "70%"],
          },
        ],
      };
      myChart.setOption(totalOption);
    },

    initToday(data) {
      let xAxisData = [],
        seriesData = [];
      data.forEach((item) => {
        xAxisData.push(item.date);
        seriesData.push(item.value);
      });

      let myChart = echarts.init(document.getElementById("chartToday"));
      let option = {
        // legend: {
        //   top:"top",
        //   left:"center",
        //   data:[{name:"营业总额"},{name:"违约金"},{name:"客房消费"},{name:"房费"}]
        // },
        xAxis: {
          data: xAxisData,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return `金额：${params[0].data} <br/>日期：${params[0].name}`;
          },
          axisPointer: {
            animation: false,
          },
        },
        yAxis: {},
        series: {
          type: "line",
          data: seriesData,
        },
      };
      myChart.setOption(option);
    },
  },
  filters: {
    dateFilter(val) {},
  },
  computed: { ...mapState(["hotelInfo", "dictData"]) },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getStatistics();
        this.getLineChart();
        this.getSumFormData();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.m-left-auto {
  margin-left: auto;
}
.d-flex-y-center {
  display: flex;
  align-items: center;
}
.cont {
  .title {
    margin: 40px 0 20px;
    .title-date {
      color: #666;
      font-size: 12px;
      vertical-align: bottom;
    }
  }
  #chartTotal {
    width: 100%;
    height: 400px;
    background: #fff;
    padding: 0 0 20px;
    margin-right: 20px;
  }
  #chartToday {
    width: 100%;
    height: 400px;
    background: #fff;
    padding: 20px 0 0;
  }
  .card-box {
    justify-content: space-between;
    background: #fff;
    .card-box-item {
      width: 25%;
      padding: 30px 40px;
      background-repeat: no-repeat;
      text-align: left;
      background-position: 0 0;
      background-size: 100% 100%;
      box-sizing: border-box;
      color: #ffffff;
      border-radius: 6px;
      .border {
        width: 1px;
        height: 60px;
        background: #ccced9;
        margin-right: 30px;
      }
      .card-icon {
        width: 44px;
        height: 44px;
        margin-right: 15px;
      }
      .item-text {
        font-size: 36px;
        font-weight: 700;
      }
      .item-rect {
        background: #f1f1f1;
        width: 10px;
        height: 18px;
        margin-right: 3px;
      }
      .card-tit {
        font-size: 14px;
        color: #585858;
      }
      .card-data {
        font-size: 26px;
        color: #087ffd;
        margin: 10px 0;
        .data-icon {
          width: 48px;
          height: 48px;
        }
      }
      .card-ratio {
        font-size: 12px;
        color: #b9b9b9;
      }
      .item-rect-ac {
        background: #ff2332;
      }
    }
  }
  .chart-box {
    width: 100%;
    #chart {
      width: 90%;
      height: 400px;
    }
  }
  .tab-header {
    background: #d6e8fb;
    .tab-body-item {
      margin: 0 -1px -1px 0;
    }
  }
  .tab-body {
    // border:1px solid #CCC;
    background: #fff;
    .tab-body-item {
      border: 1px solid #ccc;
      margin: 0 -1px -1px 0;
      overflow: hidden;
    }
  }
  .tab-body,
  .tab-header {
    .tab-body-item {
      padding: 10px;
    }
  }
}
</style>
