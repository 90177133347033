import request from "@/api/service";

const api = {
  getStatistics:'/pms/businessSituation/getStatistics',
  getLineChart:'/pms/businessSituation/getLineChart',
  getSumFormData:'/pms/businessSituation/sumFormData',
  exportSummaryExcel:'/pms/businessSituation/exportSummary'
}

export function getStatistics(data){
  return request({
    url: api.getStatistics,
    method: 'post',
    headers:{"responseType": 'JSON'},
    data,
  })
}

export function getLineChart(data){
  return request({
    url: api.getLineChart,
    method: 'post',
    headers:{"responseType": 'JSON'},
    data,
  })
}

export function getSumFormData(data){
  return request({
    url: api.getSumFormData,
    method: 'post',
    headers:{"responseType": 'JSON'},
    data,
  })
}

export function exportSummaryExcel(params){
  return request({
    url:api.exportSummaryExcel,
    method:'get',
    responseType: 'blob',
    params
  })
}

